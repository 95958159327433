import React from "react"
import Layout from "../../components/Layout"
import { PageHeading } from "../../components/PageHeading"
import { Link } from "gatsby"

export default function Features() {
  return (
    <>
      <Layout title="Features | Pricelist">
        <div class="relative mt-16 px-4 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-prose text-lg">
            <PageHeading
              title="Why Pricelist"
              subtitle="Learn how Pricelist can deliver value to your company"
            />
            <p class="mt-8 text-gray-500 text-xl leading-8">Text</p>
          </div>
          <div class="prose prose-indigo prose-lg mt-6 mx-auto text-gray-500">
            <p>
              <Link to="/product/compare">Features</Link>
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}
